import React, { useContext } from 'react'
import { connect } from 'react-redux'
import { Grid } from '@material-ui/core'
import { createStyles, makeStyles } from '@material-ui/core/styles'
import Logo from '../Logo'
import { switchCenter } from '../../modules/common/session/sessionActions'
import { CentreInfoModel } from '../../modules/common/phidemData/phidemDataModel'
import { SessionReducer } from '../../modules/common/session/sessionReducer'
import { useHistory } from 'react-router-dom'
import { PhidemDataContext } from '../../modules/common/phidemData/PhidemDataContext'
import AutocompleteCenter from '../fields/AutocompleteCenter'

const useStyles = makeStyles(() =>
	createStyles({
		select: {
			marginTop: 10
		}
	})
)

interface BlocHeaderProps {
	session: SessionReducer,
	switchCenter: (id: string | any) => void
}

const BlocHeader: React.FC<BlocHeaderProps> = (
	{
		session,
		switchCenter
	}
) => {
	const classes = useStyles()
	const history = useHistory()
	const { user: { centerList } } = useContext(PhidemDataContext)

	const updateUserCenter = (item: any) => {
		const selectedCenter = centerList.find((center: CentreInfoModel) => center.id === item)
		if (selectedCenter) {
			switchCenter({
				id: selectedCenter.id,
				type: selectedCenter.type
			})

			history.push('/')
		}
	}

	return <Grid container>
		<Grid item xs={12}><Logo /></Grid>
		{!!session.centerId && <Grid item xs={12} className={classes.select}>
			<AutocompleteCenter
				options={centerList.map((centre: CentreInfoModel) => ({
					label: `${centre.nom}${centre.service ? ` - ${centre.service}` : ''}`,
					type: centre.type,
					value: centre.id
				}))}
				onChange={(item: any) => updateUserCenter(item)}
				disabled={centerList.length === 1}
				selectedCenter={session.centerId}
			/>
		</Grid>}
	</Grid>
}

const mapStateToProps = (state: any) => ({
	session: state.session
})

const actions = {
	switchCenter
}


export default connect(mapStateToProps, actions)(BlocHeader)

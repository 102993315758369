import React, { useCallback, useContext, useEffect, useRef, useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import moment from 'moment'
import { IsotopeTableMenu } from 'isotope-client'
import { RequestStockFiltersModel, ResponseStockModel } from '../../../admin/stock/services/stockModel'
import { VaccinationStockModel } from '../../../vaccination/services/vaccinationModels'
import { DOWNLOAD_FILE_TYPE, PRODUCT_EXPIRATION_TYPE } from '../../../../utils/constants'
import ExportExcelButton from '../../../../components/layout/buttons/ExportExcelButton'
import { compareLimitDate } from '../../../../utils/formUtils'
import { DATE_FORMAT, displayDate } from '../../../../utils/dateUtils'
import { PhidemDataContext } from '../../phidemData/PhidemDataContext'
import { CancelablePromise, PageResult } from '../../../../utils/fetchTypes'
import { getVaccinationStockPageMobile } from '../vaccinationStockApi'
import Paper from '@material-ui/core/Paper'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import TablePagination from '@material-ui/core/TablePagination'
import Loader from '../../../../components/layout/Loader'
import { createStyles, makeStyles } from '@material-ui/core/styles'
import { makeCancellablePromise } from '../../../../utils/fetchDownload'

interface VaccinationStockListMobileProps {
	filters: RequestStockFiltersModel
	openDialog?: (row: ResponseStockModel) => void
	isOpenDialog: boolean
	showCenter?: boolean
	showActions?: boolean
}

const useStyles = makeStyles(() =>
	createStyles({
		container: {
			padding: 8,
			marginBottom: 12
		},
		firstRow: {
			marginBottom: 8,
			paddingRight: 4
		},
		row: {
			marginBottom: 4
		},
		pagination: {
			padding: 0,
			border: 'none',
			display: 'flex',
			flexDirection: 'row-reverse'
		}
	})
)

const VaccinationStockListMobile: React.FC<VaccinationStockListMobileProps> = (
	{
		filters,
		openDialog = () => {
		},
		showCenter = false,
		showActions = false,
		isOpenDialog
	}
) => {
	const intl = useIntl()
	const classes = useStyles()
	const { user: { selectedLang, isDateFormatEn } } = useContext(PhidemDataContext)
	const fetchData = useRef<CancelablePromise>()
	const [page, setPage] = useState<number>(0)
	const [result, setResult] = useState<PageResult<VaccinationStockModel> | undefined>(undefined)
	const [loading, setLoading] = useState<boolean>(false)

	const getData = useCallback(() => {
		setLoading(true)
		if (fetchData.current) {
			fetchData.current.cancel()
			fetchData.current = undefined
		}
		fetchData.current = makeCancellablePromise(getVaccinationStockPageMobile(filters, page))
		fetchData.current.promise
			.then(setResult)
			.finally(() => setLoading(false))
	}, [setLoading, filters, page])

	useEffect(() => getData(), [getData])

	useEffect(() => setPage(0), [filters])

	// On close dialog, refresh data
	useEffect(() => {
		if (!isOpenDialog) {
			getData()
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isOpenDialog])

	// Methods to render table
	const renderActions = (row: VaccinationStockModel) => [
		{
			label: <FormattedMessage
				id="global.update"
				defaultMessage="Modifier"
				description="Update action"
			/>, action: () => {
				const stock = row.ligneBl.stock
				if (stock) {
					openDialog({
						id: row.id,
						produit: stock.produit,
						quantite: row.quantiteStock,
						peremption: stock.peremption,
						lot: stock.lot,
						centerType: row.centreVacc.type
					})
				}
			}
		}
	]
	const renderPeremption = (row: VaccinationStockModel) => {
		let limiteUtilisation = <></>
		if (!!row.limiteUtilisation) {
			let expirationDate = moment(row.limiteUtilisation)
			// On compare la date limite d'utilisation à la date de péremption du stock
			expirationDate = compareLimitDate(expirationDate, row.ligneBl?.stock?.peremption, row.ligneBl?.stock?.produit.peremptionType) || moment()
			limiteUtilisation = <Grid item>
				<Typography variant="body2">
					{displayDate(moment(expirationDate.locale(selectedLang)), DATE_FORMAT.DATETIME, isDateFormatEn)}
				</Typography>
			</Grid>
		}

		let peremption
		if (row.ligneBl.produit.peremptionType === PRODUCT_EXPIRATION_TYPE.JOUR) {
			peremption = <Grid item>
				<Typography variant="body2">
					{displayDate(moment(row.ligneBl.stock ? row.ligneBl.stock.peremption : new Date()), DATE_FORMAT.DATE, isDateFormatEn)}
				</Typography>
			</Grid>
		} else {
			peremption = <Grid item>
				<Typography variant="body2">
					{displayDate(moment(row.ligneBl.stock ? row.ligneBl.stock.peremption : new Date()).locale(selectedLang), DATE_FORMAT.MONTH_YEAR, isDateFormatEn)}
				</Typography>
			</Grid>
		}

		return <>{peremption}{limiteUtilisation}</>

	}

	if (loading) {
		return <Loader />
	}

	return (<>
			{(!result || result.content.length === 0) ? <></>
				:
				<>
					{result.content.map(row => (<Paper key={row.id} className={classes.container}>
						<Grid item container xs={12}>
							<Grid item container xs={12} className={classes.firstRow} justify="space-between">
								<Grid item xs={10}>
									<Typography variant="body2">{row.ligneBl.produit.nomCommercial}</Typography>
								</Grid>
								{showActions && <Grid item>
									<IsotopeTableMenu actions={renderActions(row)} element={row} />
								</Grid>}
							</Grid>
							<Grid item xs={12}>
								<Typography variant="body2">
									<FormattedMessage
										id="stockEdit.dataTable.deliverySlipNumber"
										defaultMessage="Numéro de BL"
										description="Header column name"
									/>
									: {row.ligneBl.numeroBl}
								</Typography>
							</Grid>
							<Grid item container xs={12} justify="space-between" className={classes.row}>
								<Grid item xs={6}>
									<Typography variant="body2">
										<FormattedMessage
											id="global.functionnal.batch"
											defaultMessage="Numéro de lot"
											description="Header column name"
										/>
										: {row.ligneBl.stock ? row.ligneBl.stock.lot : ''}
									</Typography>
								</Grid>
								<Grid item>
									<Typography variant="body2">
										{row.quantiteStock}
										<FormattedMessage
											id="global.functionnal.conditioningType"
											defaultMessage={row.ligneBl.produit.typeConditionnement}
											description="Conditioning type"
											values={
												{
													count: row.quantiteStock,
													conditioningType: row.ligneBl.produit.typeConditionnement
												}
											}
										/>
									</Typography>
								</Grid>
							</Grid>
							<Grid item container xs={12} className={classes.row} justify="space-between">
								{renderPeremption(row)}
							</Grid>
							{showCenter && <Grid item xs={12} className={classes.row}>
								<Typography variant="body2">
									<FormattedMessage
										id="stockEdit.dataTable.centerName"
										defaultMessage="Centre"
										description="Header column name"
									/>
									: {row.centreVacc.nom}
								</Typography>
							</Grid>}
						</Grid>
					</Paper>))}
					<TablePagination
						className={classes.pagination}
						rowsPerPageOptions={[]}
						colSpan={3}
						count={result.totalElements}
						rowsPerPage={5}
						page={page}
						onChangePage={(ev, newPage) => setPage(newPage)}
						labelDisplayedRows={({ from, to, count }) => (
							intl.formatMessage({ id: 'isotope.datatable.footer.pagination' }, {
								startIndex: from,
								endIndex: to,
								total: count
							})
						)}
					/>
				</>
			}
			<ExportExcelButton
				url="/vaccination/stock/export"
				fileName={DOWNLOAD_FILE_TYPE.VACCINATION_STOCK.outputFile}
				filters={filters}
			/>
		</>
	)
}

export default VaccinationStockListMobile

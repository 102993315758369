import { createIsotopeTheme } from 'isotope-client'
import { colors } from '../utils/constants'

const theme = createIsotopeTheme({
	overrides: {
		MuiMenuItem: {
			root: {
				whiteSpace: 'inherit',
				'&$selected': {
					color: colors.primary
				}
			}
		},
		MuiCheckbox: {
			root: {
				color: colors.primary
			},
			colorSecondary: {
				'&$checked': {
					color: colors.primary
				}
			}
		},
		MuiButton: {
			root: {
				borderRadius: 4,
				padding: '5px 40px'
			},
			outlined: {
				padding: '4px 40px',
				borderWidth: 2
			}
		},
		MuiRadio: {
			colorSecondary: {
				'&$checked': {
					color: colors.primary
				}
			}
		},
		MuiFormControl: {
			root: {
				width: '100%'
			}
		},
		MuiInput: {
			root: {
				width: '100%'
			}
		},
		formselect: {
			selectCss: {
				width: '100%'
			},
			bootstrapFormLabel: {
				fontWeight: 'normal',
				color: colors.text
			},
			marginNoError: {
				marginBottom: 12
			},
			errorText: {
				marginBottom: 0
			},
			noErrorInput: {
				border: '1px solid',
				borderColor: colors.notFound
			}
		},
		forminput: {
			textFieldRoot: {
				width: '100%'
			},
			bootstrapFormLabel: {
				fontWeight: 'normal',
				color: colors.text,
				whiteSpace: 'nowrap'
			},
			marginNoError: {
				marginBottom: 12
			},
			errorText: {
				marginBottom: 0
			},
			noErrorInput: {
				border: '1px solid',
				borderColor: colors.notFound
			},
			textFieldInput: {

				height: '1.5em'
			}
		},
		multiplechoice: {
			wrapper: {
				maxHeight: 400,
				overflowX: 'auto'
			}
		},
		MuiFilledInput: {
			root: {
				backgroundColor: colors.input
			},
			underline: {
				borderBottomWidth: '0px !important'
			}
		},
		MuiTooltip: {
			tooltip: {
				fontSize: 12
			}
		},
		page: {
			contentFrame: {
				backgroundColor: colors.background,
				backgroundImage: 'url("/img/picto-logo-filigrane.svg")',
				backgroundRepeat: 'no-repeat',
				backgroundPosition: 'bottom right',
				backgroundSize: '40%'
			}
		},
		snackbar: {
			success: {
				backgroundColor: colors.snackSuccess
			},
			error: {
				backgroundColor: colors.snackError
			}
		},
		'isotope-menu': {
			blocLogo: {
				height: 'auto'
			}
		}
	},
	login: {
		background: 'url(\'img/connexion.jpeg\')'
	},
	typography: {
		fontFamily: 'Roboto',
		h1: {
			fontSize: '1.5rem',
			fontWeight: 500,
			marginTop: 32,
			marginBottom: 8,
			color: colors.text
		},
		h2: {
			fontSize: 24,
			fontWeight: 400,
			marginBottom: 12,
			color: colors.text
		},
		h3: {
			fontSize: 20,
			fontWeight: 500,
			marginTop: 24,
			marginBottom: 0,
			color: colors.text
		},
		h4: {
			fontSize: 16,
			fontWeight: 400,
			color: colors.text
		},
		body1: {
			fontSize: 12,
			fontWeight: 400,
			color: colors.text
		},
		body2: {
			fontSize: 12,
			fontWeight: 400,
			color: colors.text
		}
	},
	palette: {
		primary: {
			main: colors.primary,
			contrastText: '#FFFFFF'
		},
		secondary: {
			main: colors.secondary,
			contrastText: '#FFFFFF'
		},
		text: {
			main: colors.text
		}
	}
})

export default theme

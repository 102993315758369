import React from 'react'
import { initApplication, initCentre, PhidemDataModel } from './phidemDataModel'

const data: PhidemDataModel = {
	user: {
		centerList: [],
		selectedCenter: initCentre,
		isAdmin: false,
		isAdminDispatch: false,
		selectedLang: '',
		isDateFormatEn: false
	},
	vaccins: [],
	products: [],
	templates: [],
	referentialData: {
		trackers: [],
		containers: [],
		carriers: []
	},
	application: initApplication,
	valueList: {},
	reloadReferentiel: () => {
	}
}

export const PhidemDataContext = React.createContext(data)

import React, { useCallback, useContext, useEffect, useState } from 'react'
import { generateProductStatFromProductContextID } from './dashboardUtils'
import Grid from '@material-ui/core/Grid'
import ProductCount from './components/ProductCount'
import StorageIcon from '@material-ui/icons/Storage'
import TransformIcon from '@material-ui/icons/Transform'
import DeleteIcon from '@material-ui/icons/Delete'
import Typography from '@material-ui/core/Typography'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import { FormattedMessage } from 'react-intl'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { CENTER_TYPE, CENTER_TYPE_LOWERCASE, SCREEN_SIZE, TDB_COUNT } from '../../../utils/constants'
import PageLayout from '../../../components/layout/PageLayout'
import { getDashboardProducts } from './dashboardApi'
import { DashboardProductStatModel, ResponseDashboardProductsSearchModel } from './dashboardProductModel'
import { PhidemDataContext } from '../../common/phidemData/PhidemDataContext'

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        titleContainer: {
            marginTop: 32,
            width: 'fit-content',
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center'
        },
        count: {
            fontWeight: 'bold',
            color: theme.palette.primary.main
        },
        dataCount: {
            marginTop: 25,
            marginBottom: 25
        }
    })
)


const Dashboard: React.FC = () => {
    const { vaccins, user: { selectedCenter } } = useContext(PhidemDataContext)
    const [productStatList, setProductStatList] = useState<DashboardProductStatModel[]>([])
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const isLargeScreen = useMediaQuery(`(min-width: ${SCREEN_SIZE.LARGE}px)`)
    const classes = useStyles()

    const getProducts = useCallback(() => {
        setIsLoading(true)

        return getDashboardProducts(CENTER_TYPE_LOWERCASE[selectedCenter.type as keyof typeof CENTER_TYPE_LOWERCASE])
            .then((response: ResponseDashboardProductsSearchModel) => {
                setProductStatList(vaccins.map(
                    (vaccin) => generateProductStatFromProductContextID(vaccin.id, response.results, vaccins)
                ))
            })
            .finally(() => {
                setIsLoading(false)
            })
    }, [vaccins, selectedCenter.type])

    useEffect(() => {
        getProducts()
    }, [getProducts])

    return (
        <div style={{
            marginLeft: isLargeScreen ? "13vw" : 'inherit'
        }}>
            <PageLayout>
                {!isLoading && productStatList.length > 0 &&
                productStatList.map((productStat: DashboardProductStatModel) => {
                    return (<>
                        <div className={classes.titleContainer}>
                            <Typography variant="h1" style={{ margin: 0 }}>{productStat.nomCommercial}</Typography>
                        </div>
                        <Typography variant="h3">
                            <FormattedMessage
                                id="common.dashboard.productStatLabel.availableStock"
                                defaultMessage="Stock à date"
                                description="Available stock label"
                            />
                            <span className={classes.count}>{productStat.countStockRestant}</span>
                            <span>
                            <FormattedMessage
                                id="global.functionnal.conditioningType"
                                defaultMessage={productStat.typeConditionnement}
                                description="Conditioning type"
                                values={
                                    {
                                        count: productStat.countStockRestant,
                                        conditioningType: productStat.typeConditionnement
                                    }
                                }
                            />
                        </span>
                        </Typography>
                        <Grid item container spacing={5} className={classes.dataCount}>
                            <ProductCount
                                day={productStat.day.countStock}
                                week={productStat.week.countStock}
                                month={productStat.month.countStock}
                                typeCount={TDB_COUNT.ENTRY_STOCK}
                                conditionningType={productStat.typeConditionnement || ''}
                                icon={<StorageIcon />}
                            />
                            <ProductCount
                                day={productStat.day.countExit}
                                week={productStat.week.countExit}
                                month={productStat.month.countExit}
                                typeCount={selectedCenter.type === CENTER_TYPE.VACCINATION ? TDB_COUNT.EXIT_FRIDGE : TDB_COUNT.PREPARATION}
                                conditionningType={productStat.typeConditionnement || ''}
                                icon={<TransformIcon />}
                            />
                            <ProductCount
                                day={productStat.day.countDechet}
                                week={productStat.week.countDechet}
                                month={productStat.month.countDechet}
                                typeCount={TDB_COUNT.DECHET}
                                conditionningType={productStat.typeConditionnement || ''}
                                icon={<DeleteIcon />}
                            />
                        </Grid>
                    </>)
                })
                }
            </PageLayout>
        </div>
    )
}

export default Dashboard

import React, { useContext } from 'react'
import { FormattedMessage } from 'react-intl'
import moment from 'moment'
import { SpringDataTable } from 'isotope-client'
import NotFoundPage from '../../../../components/layout/NotFoundPage'
import { RequestStockFiltersModel, ResponseStockModel } from '../../../admin/stock/services/stockModel'
import { VaccinationStockModel } from '../../../vaccination/services/vaccinationModels'
import { DOWNLOAD_FILE_TYPE, PRODUCT_EXPIRATION_TYPE, SELECT_VALUES } from '../../../../utils/constants'
import ExportExcelButton from '../../../../components/layout/buttons/ExportExcelButton'
import { compareLimitDate } from '../../../../utils/formUtils'
import { DATE_FORMAT, displayDate } from '../../../../utils/dateUtils'
import { PhidemDataContext } from '../../phidemData/PhidemDataContext'

interface VaccinationStockListDesktopProps {
	filters: RequestStockFiltersModel,
	openDialog?: (row: ResponseStockModel) => void,
	showCenter?: boolean,
	showActions?: boolean
}

const VaccinationStockListDesktop: React.FC<VaccinationStockListDesktopProps> = (
	{
		filters,
		openDialog = () => {
		},
		showCenter = false,
		showActions = false
	}
) => {
	const { user: { selectedLang, isDateFormatEn } } = useContext(PhidemDataContext)
	const getHeaders = () => {
		const headers = [
			{
				key: 'ligneBl.stock.lot',
				name: <FormattedMessage
					id="global.functionnal.batch"
					defaultMessage="Numéro de lot"
					description="Header column name"
				/>,
				sortable: true,
				render: (row: VaccinationStockModel) => <span>{row.ligneBl.stock ? row.ligneBl.stock.lot : ''}</span>
			},
			{
				key: 'ligneBl.numeroBl',
				name: <FormattedMessage
					id="stockEdit.dataTable.deliverySlipNumber"
					defaultMessage="Numéro de BL"
					description="Header column name"
				/>,
				sortable: true,
				render: (row: VaccinationStockModel) => <span>{row.ligneBl.numeroBl}</span>
			},
			{
				key: 'quantiteStock',
				name: <FormattedMessage
					id="global.functionnal.quantity"
					defaultMessage="Quantité"
					description="Header column name"
				/>,
				sortable: true,
				render: (row: VaccinationStockModel) => <span>
                            <span>{row.quantiteStock}</span>
                            <FormattedMessage
	                            id="global.functionnal.conditioningType"
	                            defaultMessage={row.ligneBl.produit.typeConditionnement}
	                            description="Conditioning type"
	                            values={
		                            {
			                            count: row.quantiteStock,
			                            conditioningType: row.ligneBl.produit.typeConditionnement
		                            }
	                            }
                            />
                        </span>
			}
		]

		if (showCenter) {
			headers.push({
				key: 'centreVacc.nom',
				name: <FormattedMessage
					id="stockEdit.dataTable.centerName"
					defaultMessage="Centre"
					description="Header column name"
				/>,
				sortable: true,
				render: (row: VaccinationStockModel) => <span>{row.centreVacc.nom}</span>
			})
		}

		headers.push({
			key: 'limiteUtilisation',
			name: <FormattedMessage
				id="stockEdit.dataTable.usageLimit"
				defaultMessage="Limite d'utilisation"
				description="Header column name"
			/>,
			sortable: false,
			render: (row: VaccinationStockModel) => {
				if (!row.limiteUtilisation) {
					return <></>
				}
				let expirationDate = moment(row.limiteUtilisation)
				// On compare la date limite d'utilisation à la date de péremption du stock
				expirationDate = compareLimitDate(expirationDate, row.ligneBl?.stock?.peremption, row.ligneBl?.stock?.produit.peremptionType) || moment()

				return <span>{
					displayDate(moment(expirationDate.locale(selectedLang)), DATE_FORMAT.DATETIME, isDateFormatEn)}</span>
			}
		})

		headers.push({
			key: 'ligneBl.stock.peremption',
			name: <FormattedMessage
				id="global.functionnal.expiration"
				defaultMessage="Péremption"
				description="Header column name"
			/>,
			sortable: true,
			render: (row: VaccinationStockModel) => {
				if (row.ligneBl.produit.peremptionType === PRODUCT_EXPIRATION_TYPE.JOUR) {
					return <span>{displayDate(moment(row.ligneBl.stock ? row.ligneBl.stock.peremption : new Date()), DATE_FORMAT.DATE, isDateFormatEn)}</span>
				}
				return <span>{displayDate(moment(row.ligneBl.stock ? row.ligneBl.stock.peremption : new Date()).locale(selectedLang), DATE_FORMAT.MONTH_YEAR, isDateFormatEn)}</span>
			}
		})

		if (!filters?.idProduit || filters?.idProduit === SELECT_VALUES.ALL) {
			headers.unshift({
				key: 'ligneBl.stock.produit.nomCommercial',
				name: <FormattedMessage
					id="global.functionnal.product"
					defaultMessage="Produit"
					description="Header column name"
				/>,
				sortable: true,
				render: (row: VaccinationStockModel) => <span>{row.ligneBl.produit.nomCommercial}</span>
			})
		}

		return headers
	}

	return (<>
			<SpringDataTable
				apiUrl="/vaccination/stock"
				nom="vaccinationStockListDesktop"
				headers={getHeaders()}
				filters={filters}
				noResultFragment={<NotFoundPage />}
				makeActions={(row: VaccinationStockModel) => [
					{
						label: <FormattedMessage
							id="global.update"
							defaultMessage="Modifier"
							description="Update action"
						/>, action: () => {
							const stock = row.ligneBl.stock
							if (stock) {
								openDialog({
									id: row.id,
									produit: stock.produit,
									quantite: row.quantiteStock,
									peremption: stock.peremption,
									lot: stock.lot,
									centerType: row.centreVacc.type
								})
							}
						}
					}
				]}
				showActions={showActions}
			/>
			<ExportExcelButton
				url="/vaccination/stock/export"
				fileName={DOWNLOAD_FILE_TYPE.VACCINATION_STOCK.outputFile}
				filters={filters}
			/>
		</>
	)
}

export default VaccinationStockListDesktop

import React, { useContext } from 'react'
import moment from 'moment'
import { FormattedMessage, useIntl } from 'react-intl'
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import DeleteIcon from '@material-ui/icons/Delete'
import EditIcon from '@material-ui/icons/Edit'
import Link from '@material-ui/core/Link'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableFooter from '@material-ui/core/TableFooter'
import TableRow from '@material-ui/core/TableRow'
import Paper from '@material-ui/core/Paper'
import { colors, SCREEN_SIZE, TRANSFERT_TYPE } from '../../../utils/constants'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import WarningIcon from '@material-ui/icons/Warning'
import classNames from 'classnames'
import { compareLimitDate } from '../../../utils/formUtils'
import { DATE_FORMAT, displayDate, getPeremptionDate } from '../../../utils/dateUtils'
import { PhidemDataContext } from '../../../modules/common/phidemData/PhidemDataContext'
import { ProductTableModel } from './productsTableModels'

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		table: {
			width: '100%'
		},
		headerCell: {
			backgroundColor: colors.primary,
			color: colors.elementOnPrimary,
			padding: '10px 0px'
		},
		bodyCell: {
			color: colors.text,
			padding: '10px 0px'
		},
		tableFooter: {
			color: colors.primary
		},
		cursor: {
			cursor: 'pointer'
		},
		margin: {
			marginRight: 10
		}
	})
)

interface ProductsTableProps {
	withTitle?: boolean
	title?: string,
	canEdit?: boolean
	frostedMode?: boolean
	handleEdit?: (index: number) => void
	handleDelete?: (index: number) => void
	handleAdd?: () => void
	produits: ProductTableModel[]
}

const ProductsTable: React.FC<ProductsTableProps> = (
	{
		withTitle = true,
		title = 'common.exitStock.formSection.preparation',
		canEdit = false,
		frostedMode = false,
		handleEdit,
		handleDelete,
		handleAdd,
		produits
	}
) => {
	const classes = useStyles()
	const { user: { isDateFormatEn } } = useContext(PhidemDataContext)
	const intl = useIntl()
	const isLargeScreen = useMediaQuery(`(min-width: ${SCREEN_SIZE.LARGE}px)`)

	const displayPeremption = (produit: ProductTableModel) => {
		if (produit.peremption) {
			if (!produit.isRetour && !!produit.transfertType && produit.transfertType !== TRANSFERT_TYPE.CONGELE) {
				// On affiche la péremption
				// Pour un transfert -70 -> -20 = date transfert
				const usageLimitDate = compareLimitDate(moment(produit.transfertDate), produit.peremption, produit.peremptionType) || moment()
				return displayDate(usageLimitDate, DATE_FORMAT.DATE, isDateFormatEn)
			}
			return getPeremptionDate(isDateFormatEn, produit.peremption, produit.peremptionType)
		}
		return ''
	}

	return (
		<Grid item xs={12}>
			{withTitle && <Grid item xs={12}>
				<Typography variant={isLargeScreen ? 'h1' : 'h3'}>
					<FormattedMessage
						id={title}
						defaultMessage="Liste des produits"
						description="Form section title"
					/>
				</Typography>
			</Grid>}
			<TableContainer component={Paper}>
				<Table className={classes.table} aria-label="customized table">
					<TableHead>
						<TableRow>
							<TableCell className={classes.headerCell} align="center">
								<FormattedMessage
									id="global.functional.product"
									defaultMessage="Produit"
									description="Produit"
								/>
							</TableCell>
							{isLargeScreen &&
								<>
									<TableCell className={classes.headerCell} align="center">
										<FormattedMessage
											id="dispatch.exitStock.global.functionnal.batch"
											defaultMessage="Lot"
											description="Lot"
										/>
									</TableCell>
									<TableCell className={classes.headerCell} align="center">
										<FormattedMessage
											id="global.functionnal.expiration"
											defaultMessage="Péremption"
											description="Péremption"
										/>
									</TableCell>
								</>
							}
							<TableCell className={classes.headerCell} align="center">
								<FormattedMessage
									id="global.functionnal.quantity"
									defaultMessage="Quantité"
									description="Quantité"
								/>
							</TableCell>
							{(isLargeScreen && !frostedMode) &&
								<TableCell className={classes.headerCell} align="center">
									<FormattedMessage
										id="dispatch.exitStock.table.header.exitDatetime"
										defaultMessage="Sortie congélateur"
										description="Sortie congélateur"
									/>
								</TableCell>
							}
							{canEdit && <TableCell className={classes.headerCell} align="center" />}
						</TableRow>
					</TableHead>
					<TableBody>
						{
							// @ts-ignore
							produits.map((produit, index) => {
								let lotSuffix = ''
								if (produit.isRetour && produit.usageLimit) {
									lotSuffix = ` - ${displayDate((compareLimitDate(moment(produit.usageLimit), produit.peremption, produit.peremptionType) || moment()), DATE_FORMAT.DATE, isDateFormatEn)}`
								} else if (!!produit.transfertType && produit.transfertType !== TRANSFERT_TYPE.CONGELE) {
									lotSuffix = ` (${intl.formatMessage({ id: `enum.transfertType.${produit.transfertType}` })})`
									if (produit.usageLimit) {
										lotSuffix = `${lotSuffix} - ${displayDate((compareLimitDate(moment(produit.usageLimit), produit.peremption, produit.peremptionType) || moment()), DATE_FORMAT.DATE, isDateFormatEn)}`
									}
								}
								return (
									<TableRow key={`produit-${index}`}>
										<TableCell align="center" className={classes.bodyCell}>
											<Grid item container justify="center" alignItems="center">
												{produit.isRetour && <WarningIcon />}
												<span>{produit.nom}</span>
											</Grid>

										</TableCell>
										{isLargeScreen &&
											<>
												<TableCell align="center" className={classes.bodyCell}>
													{produit.lot && <span>{`${produit.lot}${lotSuffix}`}</span>}
													{produit.reference && <>
														{produit.lot && <br />}
														<span>({produit.reference})</span>
													</>}
												</TableCell>
												<TableCell align="center" className={classes.bodyCell}>{displayPeremption(produit)}</TableCell>
											</>
										}
										<TableCell align="center" className={classes.bodyCell}>{produit.quantite}
											<FormattedMessage
												id="global.functionnal.conditioningType"
												defaultMessage="Conditionning"
												description="Conditioning type"
												values={
													{
														count: produit.quantite,
														conditioningType: produit.unit
													}
												}
											/>
										</TableCell>
										{(isLargeScreen && !frostedMode) &&
											<TableCell align="center" className={classes.bodyCell}>{produit.sortie ? displayDate(moment(produit.sortie), DATE_FORMAT.DATETIME, isDateFormatEn) : ''}</TableCell>
										}
										{canEdit && <TableCell padding="none" align="center">
											{handleEdit && <EditIcon className={classNames(classes.cursor, classes.margin)} fontSize="small" aria-label="edit" onClick={() => handleEdit(index)} />}
											{handleDelete && <DeleteIcon className={classes.cursor} fontSize="small" onClick={() => handleDelete(index)} />}
										</TableCell>}

									</TableRow>
								)
							})}
					</TableBody>
					{canEdit && <TableFooter>
						<TableCell className={classes.tableFooter} colSpan={6}>
							<Link onClick={handleAdd} color="primary" className={classes.cursor}>
								<FormattedMessage
									id="dispatch.exitStock.table.action.addProduct"
									defaultMessage="Ajouter un produit"
									description="Ajouter un produit"
								/>
							</Link>
						</TableCell>
					</TableFooter>}
				</Table>
			</TableContainer>
		</Grid>)
}

export default ProductsTable

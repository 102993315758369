import React, { useContext, useEffect, useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import Grid from '@material-ui/core/Grid'
import { createStyles, makeStyles, Theme, withTheme } from '@material-ui/core/styles'
import { injectToolbarData } from 'isotope-client'
import CircularProgress from '@material-ui/core/CircularProgress'
import { useParams } from 'react-router-dom'
import Typography from '@material-ui/core/Typography'
import NotFoundPage from '../../../components/layout/NotFoundPage'
import DisplayLineData from '../../../components/layout/DisplayLineData'
import TotalCountCaption from '../../../components/fields/TotalCountCaption'
import DownloadButton from '../../../components/layout/buttons/DownloadButton'
import { DOWNLOAD_FILE_TYPE, SCRAPPING_OTHER_OPTION, SCREEN_SIZE, VALUE_LIST_SHORTCUT } from '../../../utils/constants'
import { getDechetByID } from '../services/vaccinationApi'
import { VaccinationScrappingModel } from '../services/vaccinationModels'
import { PhidemDataContext } from '../../common/phidemData/PhidemDataContext'

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        container: {
            marginTop: theme.spacing(3),
            display: 'flex',
            flexDirection: 'column',
        },
        circularProgressRoot: {
            width: '100%',
            height: '100vh',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
        },
        firstTitle: {
            marginTop: 0
        },
        buttonRoot: {
            width: '100%',
            display: 'flex',
            justifyContent: 'space-evenly'
        },
        button: {
            margin: theme.spacing(2),
        },
        dosageContainer: {
            margin: '-9px 0 6px 12px'
        }
    }),
)

interface Values {
    product: string
    unitProduct: string
    lot: string
    limitDate: string
    quantite: number
    causeDechet: string
    commentaireDechet?: string
    doseTotal: number
    numeroBl: string
}

interface MatchParamsModel {
    id: string
}

interface ScrappingPrintProps {
    theme: Theme
}

const ScrappingPrint: React.FC<ScrappingPrintProps> = ({ theme }) => {
    const classes = useStyles()
    const intl = useIntl()
    const { valueList } = useContext(PhidemDataContext)
    const isLargeScreen = useMediaQuery(`(min-width: ${SCREEN_SIZE.LARGE}px)`)
    const { id: idInUrl } = useParams<MatchParamsModel>()
    const [fetchedValues, setFetchedValues] = useState<Values | undefined>()
    const [isLoading, setIsLoading] = useState<boolean>(true)
    const scrappingCauseOptions = valueList[VALUE_LIST_SHORTCUT.SCRAPPING_CAUSE] || {}

    useEffect(() => {
        getDechetByID(idInUrl)
            .then((values: VaccinationScrappingModel) => {
                return setFetchedValues({
                    product: values.nomCommercialProduit,
                    quantite: values.quantite,
                    unitProduct: values.typeCondProduit,
                    doseTotal: values.quantite * values.nbDosesProduit,
                    lot: values.lot,
                    causeDechet: values.cause || '',
                    commentaireDechet: values.commentaireMouvement,
                    limitDate: values.limiteUtilisation || '',
                    numeroBl: values.numeroBl
                })
            })
            .finally(() => setIsLoading(false))
    }, [idInUrl])

    if (isLoading) {
        return <div
            className={classes.container}
            style={{
                alignItems: isLargeScreen ? 'flex-start' : 'center',
                marginLeft: isLargeScreen ? '24px' : 0
            }}
        >
            <div className={classes.circularProgressRoot}>
                <CircularProgress color='primary' size={100} />
            </div>
        </div>
    }

    return (
        <div
            className={classes.container}
            style={{
                alignItems: isLargeScreen ? 'flex-start' : 'center',
                margin: isLargeScreen ?
                    `${theme.spacing(3)}px ${theme.spacing(3)}px 64px 13vw` :
                    `${theme.spacing(1)} ${theme.spacing(1)} 64px ${theme.spacing(1)}`,
                width: isLargeScreen ? 600 : 300,
            }}
        >
            {!fetchedValues
                ?
                <NotFoundPage />
                :
                <Grid
                    item
                    container
                    direction='row'
                    justify='flex-start'
                    xs={10} md={12}
                    spacing={2}
                >
                    <Grid item xs={12}>
                        <Typography
                            className={classes.firstTitle}
                            variant={isLargeScreen ? 'h1' : 'h3'}
                        >
                            <FormattedMessage
                                id="dispatch.stock.formSection.productID"
                                defaultMessage="Identification du produit"
                                description="Form section title"
                            />
                        </Typography>
                    </Grid>
                    <Grid item xs={10} md={4}>
                        <DisplayLineData
                            label={
                                <FormattedMessage
                                    id="global.functional.product"
                                    defaultMessage="Produit"
                                    description="Product to select"
                                />
                            }
                            data={fetchedValues.product}
                        />
                    </Grid>
                    <Grid item xs={6} md={4}>
                        <DisplayLineData
                            label={
                                <FormattedMessage
                                    id="dispatch.stock.unitProduct"
                                    defaultMessage="Conditionnement"
                                    description="Conditioning"
                                />
                            }
                            data={fetchedValues.unitProduct}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant={isLargeScreen ? 'h1' : 'h3'}>
                            <FormattedMessage
                                id="vaccination.scrapping.formSection.batchNumber"
                                defaultMessage="2. Sélectionner le lot"
                                description="Form section title"
                            />
                        </Typography>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <DisplayLineData
                            label={
                                <FormattedMessage
                                    id="vaccination.exitFridge.batchNumber"
                                    defaultMessage="Numéro de lot / bon de livraison"
                                    description="Batch number"
                                />
                            }
                            data={`${fetchedValues.lot} / ${fetchedValues.numeroBl}`}
                        />
                    </Grid>
                    {fetchedValues.limitDate &&
					<Grid item xs={12} md={4}>
						<DisplayLineData
							label={
                                <FormattedMessage
                                    id="global.functionnal.usageLimitDate"
                                    defaultMessage="Limite d'utilisation"
                                    description="Input for limit date"
                                />
                            }
							data={fetchedValues.limitDate}
						/>
					</Grid>
                    }
                    <Grid item xs={12}>
                        <Typography variant={isLargeScreen ? 'h1' : 'h3'}>
                            <FormattedMessage
                                id="vaccination.scrapping.formSection.usingProduct"
                                defaultMessage="3. Saisir la quantité"
                                description="Form section title"
                            />
                        </Typography>
                    </Grid>
                    <Grid item xs={6} md={3}>
                        <DisplayLineData
                            label={
                                <FormattedMessage
                                    id='dispatch.stock.quantity'
                                    defaultMessage='Quantité'
                                    description='Quantity of selected product'
                                />
                            }
                            data={fetchedValues.quantite}
                        />
                    </Grid>
                    <TotalCountCaption count={fetchedValues.doseTotal} isNextToInput={false} disabled />
                    <Grid item xs={12}>
                        <Grid item xs={12} md={8}>
                            <DisplayLineData
                                label={
                                    <FormattedMessage
                                        id="common.scrapping.scrappingCause"
                                        defaultMessage="Cause de la mise en déchet"
                                        description="Scrapping cause"
                                    />}
                                data={fetchedValues.causeDechet === SCRAPPING_OTHER_OPTION ?
                                    intl.formatMessage({ id: 'common.scrapping.scrappingOtherOption' })
                                    : scrappingCauseOptions[fetchedValues.causeDechet]}
                            />
                        </Grid>
                        {fetchedValues.causeDechet === SCRAPPING_OTHER_OPTION && <Grid item xs={12} md={8}>
                            <DisplayLineData
                                label={
                                    <FormattedMessage
                                        id="common.scrapping.scrappingComment"
                                        defaultMessage="Commentaire"
                                        description="Scrapping comment"
                                    />}
                                data={fetchedValues.commentaireDechet}
                            />
                        </Grid>}
                    </Grid>
                    <div
                        className={classes.buttonRoot}
                        style={{ flexDirection: isLargeScreen ? 'row' : 'column' }}
                    >
                        <DownloadButton
                            className={classes.button}
                            label={<FormattedMessage
                                id="button.print.labelScrapping"
                                defaultMessage="Etiquette mise en déchet"
                                description="Message on print label button"
                            />}
                            url={`/vaccination/dechet/${idInUrl}/document?documentType=${DOWNLOAD_FILE_TYPE.VACCINATION_DECHET.type}`}
                            fileName={DOWNLOAD_FILE_TYPE.VACCINATION_DECHET.outputFile}
                        />
                    </div>
                </Grid>
            }
        </div>
    )
}

export default injectToolbarData(() => (
    {
        title: <FormattedMessage
            id="menu.vaccinationCenter.dechet"
            defaultMessage="Mise en déchet"
            description="Menu title"
        />
    }
))(withTheme(ScrappingPrint))

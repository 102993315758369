import React, { useContext, useState } from 'react'
import { Field, Form } from 'react-final-form'
import MenuItem from '@material-ui/core/MenuItem'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import moment, { Moment } from 'moment'
import { FormattedMessage, useIntl } from 'react-intl'
import { FormInput, FormSelect } from 'isotope-client'
import { OnChange } from 'react-final-form-listeners'
import FieldProduct from '../../../components/fields/FieldProduct'
import FieldUnitProduct from '../../../components/fields/FieldUnitProduct'
import Button from '../../../components/layout/buttons/Button'
import ErrorMessageRequired from '../../../components/layout/errors/ErrorMessageRequired'
import { EXIT_MODE, EXIT_TYPE, SCREEN_SIZE, TRANSFERT_TYPE } from '../../../utils/constants'
import { getAvailableBatchNumberListByProductID, postExitFridge } from '../services/vaccinationApi'
import { ResponseBatchNumberModelItem } from '../services/vaccinationModels'
import PageFormContainer from '../../../components/layout/PageFormContainer'
import FieldQuantity from '../../../components/fields/FieldQuantity'
import { PhidemDataContext } from '../../common/phidemData/PhidemDataContext'
import { compose } from 'redux'
import { injectActions as injectSnackbarActions } from 'isotope-client/components/snackbar/services/snackbarInjector'
import errorsMapper from '../../../utils/errorsMapper'
import TotalCountCaption from '../../../components/fields/TotalCountCaption'
import FieldRadio from '../../../components/fields/FieldRadio'
import { CentreModel } from '../../common/phidemData/phidemDataModel'
import { useHistory } from 'react-router-dom'
import ErrorPositiveValue from '../../../components/layout/errors/ErrorPositiveValue'
import { compareLimitDate } from '../../../utils/formUtils'
import { DATE_FORMAT_IN_FORM, formatDateInForm } from '../../../utils/dateUtils'

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		submitButtonRoot: {
			width: '100%',
			margin: theme.spacing(1),
			display: 'flex'
		},
		longLabel: {
			whiteSpace: 'nowrap'
		}
	})
)

interface FormValues {
	product: string
	unitProduct: string
	batchNumberID: string
	limitDate: string
	quantite: number
	sortieType: string
	sortieMode: string
	seringue: number
	idPoint: string
	commentaire: string
}

interface ExitFridgeProps {
	snackSuccess: (value: any) => void,
	snackError: (value: any) => void,
}

const ExitFridge: React.FC<ExitFridgeProps> = (
	{
		snackSuccess,
		snackError
	}) => {
	const classes = useStyles()
	const isLargeScreen = useMediaQuery(`(min-width: ${SCREEN_SIZE.LARGE}px)`)
	const { user: { selectedCenter } } = useContext(PhidemDataContext)
	const [batchNumberList, setBatchNumberList] = useState<ResponseBatchNumberModelItem[]>([])
	const [dosesCount, setDosesCount] = useState<number>(0)
	const [showBatchList, setShowBatchList] = useState<boolean>(false)
	const [showLimitDate, setShowLimitDate] = useState<boolean>(false)
	const intl = useIntl()
	const history = useHistory()

	const fetchBatchNumberList = (productID: string) => {
		setBatchNumberList([])
		setDosesCount(0)
		if (productID) {
			getAvailableBatchNumberListByProductID(productID)
				.then((fetchedBatchNumberList: ResponseBatchNumberModelItem[]) => setBatchNumberList(fetchedBatchNumberList))
				.finally(() => setShowBatchList(true))
		}
	}

	const resetSecondPartFields = (form: any) => {
		form.change('batchNumberID', undefined)
		form.change('quantite', undefined)
		form.change('limitDate', undefined)
		setShowLimitDate(false)
		setShowBatchList(false)
	}

	const onValidate = (formValues: FormValues) => {
		const errors: any = {}

		if (!formValues.product) {
			errors.product = <ErrorMessageRequired />
		}
		if (!formValues.batchNumberID) {
			errors.batchNumberID = <ErrorMessageRequired />
		}

		if (formValues.sortieType === EXIT_TYPE.VACCINATION_POINT) {
			if (!formValues.idPoint) {
				errors.idPoint = <ErrorMessageRequired />
			}
			if (formValues.sortieMode === EXIT_MODE.FLACON) {
				if (!formValues.quantite) {
					errors.quantite = <ErrorMessageRequired />
				} else if (formValues.quantite < 1) {
					errors.quantite = <ErrorPositiveValue />
				}
			} else {
				if (!formValues.seringue) {
					errors.seringue = <ErrorMessageRequired />
				} else if (formValues.seringue < 1) {
					errors.seringue = <ErrorPositiveValue />
				}
			}
		} else {
			if (!formValues.quantite) {
				errors.quantite = <ErrorMessageRequired />
			}
		}

		return errors
	}

	const onSubmit = async (formValues: FormValues) => {
		const batchNumberItem = batchNumberList.find(
			(batchNumberItem: ResponseBatchNumberModelItem) => batchNumberItem.id === formValues.batchNumberID
		)

		if (batchNumberItem) {
			return postExitFridge({
				idLigneBl: batchNumberItem.ligneBl.id,
				quantite: formValues.quantite,
				idPoint: formValues.idPoint,
				seringue: formValues.seringue,
				sortieType: formValues.sortieType,
				commentaire: formValues.commentaire,
				typeConditionnement: formValues.sortieMode
			})
				.then((response: any) => {
					if (formValues.sortieType === EXIT_TYPE.INTERNE) {
						snackSuccess({ id: 'vaccination.exitFridge.success', defaultMessage: 'La sortie de frigo a bien été enregistrée', description: 'Success message' })
					} else {
						history.push(`/vaccination/exits/${response.id}`)
					}
				})
				.catch((e: any) => {
					const { errors, displayFields } = errorsMapper(e)
					if (displayFields) {
						snackError({ id: 'global.error.formError', defaultMessage: 'Votre formulaire comporte des erreurs', description: 'Form error message' })
					} else {
						snackError({ id: 'vaccination.exitFridge.error', defaultMessage: 'Une erreur est survenue, la sortie de frigo n\'a pas été enregistrée', description: 'Error message' })
					}
					return errors
				})
		}
	}

	return (
		<Form
			onSubmit={onSubmit}
			validate={onValidate}
			initialValues={{ sortieType: EXIT_TYPE.INTERNE, sortieMode: EXIT_MODE.FLACON }}
			render={({ handleSubmit, form, submitting, hasValidationErrors, values }) => (
				<PageFormContainer onSubmit={async event => {
					const error = await handleSubmit(event)
					if (error) {
						return error
					}
					if (!hasValidationErrors) {
						// @ts-ignore
						form.restart()
					}
				}}
				>
					<Grid item xs={12}>
						<Typography variant={isLargeScreen ? 'h1' : 'h3'}>
							<FormattedMessage
								id="dispatch.stock.formSection.productID"
								defaultMessage="Identification du produit"
								description="Form section title"
							/>
						</Typography>
					</Grid>
					<Grid item xs={7} md={4}>
						<FieldProduct />
					</Grid>
					<OnChange name="product">
						{(value) => {
							resetSecondPartFields(form)
							fetchBatchNumberList(value)
						}}
					</OnChange>
					<Grid item xs={5} md={4}>
						<FieldUnitProduct />
					</Grid>
					{showBatchList && <>
						<Grid item xs={12}>
							<Typography variant={isLargeScreen ? 'h1' : 'h3'}>
								<FormattedMessage
									id="vaccination.exitFridge.formSection.batchNumber"
									defaultMessage="2. Sélectionner le lot"
									description="Form section title"
								/>
							</Typography>
						</Grid>
						{batchNumberList.length > 0 ? <>
								<Grid item xs={12} md={6}>
									<Field
										name="batchNumberID"
										component={FormSelect}
										label={
											<FormattedMessage
												id="vaccination.exitFridge.batchNumber"
												defaultMessage="Numéro de lot / bon de livraison"
												description="Batch number"
											/>
										}
									>
										{batchNumberList.map((batchNumber: ResponseBatchNumberModelItem) => (
											<MenuItem key={batchNumber.id} value={batchNumber.id}>{`${batchNumber.ligneBl.stock ? batchNumber.ligneBl.stock.lot : ''} / ${batchNumber.ligneBl.numeroBl}`}</MenuItem>
										))}
									</Field>
									<OnChange name="batchNumberID">
										{(value) => {
											const batchNumberItem = batchNumberList.find(
												(batchNumberItem: ResponseBatchNumberModelItem) => batchNumberItem.id === value
											)

											if (batchNumberItem && batchNumberItem.ligneBl.dateSortie) {
												setShowLimitDate(true)
												// Durée de stabilité selon le stock
												// - Stock classique = stabilite28
												// - Stock suite à un transfert -20 = stabilite28TransfertMoins20
												const stability = (batchNumberItem.ligneBl?.stock?.transfertType === TRANSFERT_TYPE.MOINS_20 ? batchNumberItem.ligneBl?.stock?.produit?.stabilite28TransfertMoins20 : batchNumberItem.ligneBl?.stock?.produit?.stabilite28) || 0
												let expirationDate: Moment | undefined = moment(batchNumberItem.ligneBl.dateSortie)
													.add(stability, 'hours')

												// On compare la date limite d'utilisation à la date de péremption du stock
												expirationDate = compareLimitDate(expirationDate, batchNumberItem.ligneBl?.stock?.peremption, batchNumberItem.ligneBl?.produit.peremptionType)

												form.change('limitDate', expirationDate ? formatDateInForm(expirationDate.local(), DATE_FORMAT_IN_FORM.DATETIME) : undefined)
											}
										}}
									</OnChange>
								</Grid>
								{showLimitDate &&
									<Grid item xs={12} md={4}>
										<Field
											className={classes.longLabel}
											name="limitDate"
											component={FormInput}
											label={
												<FormattedMessage
													id="global.functionnal.usageLimitDate"
													defaultMessage="Limite d'utilisation"
													description="Input for limit date"
												/>
											}
											disabled
										/>
									</Grid>}

								<Grid item xs={12}>
									<Typography variant={isLargeScreen ? 'h1' : 'h3'}>
										<FormattedMessage
											id="vaccination.exitFridge.formSection.exitSelection"
											defaultMessage="3. Sélectionner la sortie"
											description="Form section title"
										/>
									</Typography>
								</Grid>
								<Grid item xs={12}>
									<FieldRadio
										name="sortieType"
										label={<FormattedMessage id="global.functionnal.destination" defaultMessage="Destination" description="Exit type selection" />}
										choices={Object.keys(EXIT_TYPE).map((mode: string) => ({
											label: intl.formatMessage({
												id: `vaccination.exitFridge.sortieType.${mode}`,
												defaultMessage: `${mode}`,
												description: 'Exit type option'
											}),
											value: mode
										}))}
									/>
								</Grid>

								{values.sortieType === EXIT_TYPE.VACCINATION_POINT &&
									<Grid item xs={12} md={5}>
										<Field
											name="idPoint"
											label={<FormattedMessage
												id="vaccination.exitFridge.formSection.usingProduct.vaccinationPoint"
												defaultMessage="Point de vaccination"
												description="Destination field"
											/>}
											component={FormSelect}
											options={selectedCenter.centreEnfantsByType.vaccinations.map((centre: CentreModel) => ({
												label: `${centre.nom}${centre.service ? ` - ${centre.service}` : ''}`,
												value: centre.id
											}))}
										>
											{selectedCenter.centreEnfantsByType.vaccinations.map((centre: CentreModel) => (
												<MenuItem key={centre.id} value={centre.id}>{`${centre.nom}${centre.service ? ` - ${centre.service}` : ''}`}</MenuItem>
											))}
										</Field>
									</Grid>
								}

								<Grid item xs={12}>
									<Typography variant={isLargeScreen ? 'h1' : 'h3'}>
										<FormattedMessage
											id="vaccination.exitFridge.formSection.usingProduct"
											defaultMessage="4. Saisir la quantité"
											description="Form section title"
										/>
									</Typography>
								</Grid>
								{values.sortieType === EXIT_TYPE.VACCINATION_POINT && <>
									<Grid item xs={12}>
										<FieldRadio
											name="sortieMode"
											label={<FormattedMessage id="vaccination.replenishment.modeQte" defaultMessage="Mode de saisie" description="Quantity mode selection" />}
											choices={Object.keys(EXIT_MODE).map((mode: string) => ({
												label: intl.formatMessage({
													id: `enum.exitMode.${mode}`,
													defaultMessage: `Quantity mode ${mode}`,
													description: 'Quantity mode option'
												}),
												value: mode
											}))}
										/>
									</Grid>
								</>}
								{(values.sortieType === EXIT_TYPE.INTERNE || values.sortieMode === EXIT_MODE.FLACON) && <>
									<Grid item xs={4} md={2}>
										<FieldQuantity name="quantite" required />
									</Grid>
									<TotalCountCaption count={dosesCount} />
								</>}
								{values.sortieMode === EXIT_MODE.SERINGUE && <>
									<Grid item xs={12} container>
										<Grid item xs={4} md={2}>
											<Field
												name="seringue"
												component={FormInput}
												label={<FormattedMessage id="vaccination.exitFridge.seringue" defaultMessage="Seringue" description="Comment label input" />}
												type="number"
												inputProps={{ min: 0 }}
												required
											/>
										</Grid>
									</Grid>
									<Grid item xs={12} container>
										<Grid item xs={4} md={2}>
											<Field
												name="quantite"
												component={FormInput}
												label={<FormattedMessage id="vaccination.exitFridge.flaconConsumed" defaultMessage="Nb flacons consommés" description="Comment label input" />}
												type="number"
												inputProps={{ min: 0 }}
											/>
										</Grid>
									</Grid>
								</>}
								{values.sortieType === EXIT_TYPE.VACCINATION_POINT && <Grid item xs={12}>
									<Field
										name="commentaire"
										component={FormInput}
										label={
											<FormattedMessage
												id="vaccination.replenishment.comment"
												defaultMessage="Commentaire"
												description="Comment label input"
											/>
										}
									/>
								</Grid>}
								<div
									className={classes.submitButtonRoot}
									style={{
										justifyContent: isLargeScreen ? 'flex-start' : 'center'
									}}
								>
									<Button type="submit" variant="contained" isLoading={submitting}>
										<FormattedMessage
											id="button.validate"
											defaultMessage="Valider"
											description="Message on form submission button"
										/>
									</Button>
								</div>
							</> :
							<Grid item xs={12}>
								<Typography variant={isLargeScreen ? 'body1' : 'body2'}>
									<FormattedMessage
										id="vaccination.exitFridge.batchNotFound"
										defaultMessage="Aucun lot ne possède ce produit"
										description="No batch found"
									/>
								</Typography>
							</Grid>
						}
					</>}
				</PageFormContainer>
			)}
		/>
	)
}

export default compose(injectSnackbarActions)(ExitFridge)

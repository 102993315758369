import Grid from '@material-ui/core/Grid'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { injectActions as injectSnackbarActions } from 'isotope-client/components/snackbar/services/snackbarInjector'
import moment from 'moment'
import React, { useContext, useEffect, useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { useParams } from 'react-router-dom'
import { compose } from 'redux'
import Button from '../../../components/layout/buttons/Button'
import DisplayLineData from '../../../components/layout/DisplayLineData'
import Loader from '../../../components/layout/Loader'
import PageFormContainer from '../../../components/layout/PageFormContainer'
import { DOWNLOAD_FILE_TYPE, SCREEN_SIZE } from '../../../utils/constants'
import { fetchDownload } from '../../../utils/fetchDownload'
import { PhidemDataContext } from '../../common/phidemData/PhidemDataContext'
import { getBatchNumberListByProductID, getMovementById } from '../services/vaccinationApi'
import { ResponseBatchNumberModelItem, VaccinationMouvementModel } from '../services/vaccinationModels'
import { compareLimitDate, getLimitDateWithStability } from '../../../utils/formUtils'
import { VaccinModel } from '../../admin/product/services/productModel'
import { DATE_FORMAT, displayDate } from '../../../utils/dateUtils'

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		submitButtonRoot: {
			width: '100%',
			margin: theme.spacing(1),
			display: 'flex',
			justifyContent: 'center'
		},
		longLabel: {
			whiteSpace: 'nowrap'
		}
	})
)

interface ExitFridgePrintProps {
	snackError: (value: any) => void
}

interface MatchParamsModel {
	id: string
}

const ExitFridgePrint: React.FC<ExitFridgePrintProps> = ({ snackError }) => {
	const classes = useStyles()
	const isLargeScreen = useMediaQuery(`(min-width: ${SCREEN_SIZE.LARGE}px)`)
	const { vaccins, user: { isDateFormatEn } } = useContext(PhidemDataContext)
	const intl = useIntl()
	const { id: idInUrl } = useParams<MatchParamsModel>()
	const [movement, setMovement] = useState<VaccinationMouvementModel>()
	const [batch, setBatch] = useState<ResponseBatchNumberModelItem>()
	const [isLoadingDocument, setIsLoadingDocument] = useState<boolean>(false)

	const downloadDocument = () => {
		setIsLoadingDocument(true)
		fetchDownload(`/vaccination/mouvement/${idInUrl}/etiquette`)
			.then((blob: any) => {
				const url = window.URL.createObjectURL(blob)
				const a = document.createElement('a')
				a.href = url
				a.download = DOWNLOAD_FILE_TYPE.VACCINATION_ETIQUETTE.outputFile
				a.click()
			})
			.catch(() => snackError({ id: 'global.error.downloadFailed', defaultMessage: 'Le téléchargement a échoué', description: 'Error message' }))
			.finally(() => setIsLoadingDocument(false))
	}

	useEffect(() => {
		const initValues = async () => {
			const movement: VaccinationMouvementModel = await getMovementById(idInUrl)
			setMovement(movement)
			const productId = vaccins.find((vaccin) => vaccin.nomCommercial === movement.nomCommercialProduit)?.id ?? '1'
			const batchNumbers: ResponseBatchNumberModelItem[] = await getBatchNumberListByProductID(productId)
			setBatch(batchNumbers.find((batchNumberItem) => batchNumberItem.ligneBl.id === movement.vaccinationBl?.idLigneBl))
		}
		initValues()
	}, [idInUrl, vaccins])

	if (!movement || !batch) {
		return <Loader />
	}

	const hasSeringue = !!movement.vaccinationBl?.seringueQte

	const getUsageLimit = (batch: ResponseBatchNumberModelItem) => {
		// On calcule la durée de stabilité selon le stock initial
		// - Stock classique = stabilite28
		// - Stock suite à un transfert -20 = stabilite28TransfertMoins20
		const limitUsage = getLimitDateWithStability(batch.ligneBl.produit as VaccinModel, batch.ligneBl.dateSortie, batch.ligneBl.stock?.transfertType)
		// On compare la date limite d'utilisation à la date de péremption du stock
		return displayDate((compareLimitDate(limitUsage, batch.ligneBl?.stock?.peremption, batch.ligneBl?.produit.peremptionType) || moment()).local(), DATE_FORMAT.DATETIME, isDateFormatEn)
	}

	return (
		<PageFormContainer onSubmit={() => {
		}}>
			<Grid item xs={12}>
				<Typography variant={isLargeScreen ? 'h1' : 'h3'}>
					<FormattedMessage id="dispatch.stock.formSection.productID" defaultMessage="Identification du produit" description="Form section title" />
				</Typography>
			</Grid>
			<Grid item xs={7} md={4}>
				<DisplayLineData
					label={<FormattedMessage id="global.functional.product" defaultMessage="Produit" description="Product to select" />}
					data={movement.nomCommercialProduit}
					multiline
				/>
			</Grid>
			<Grid item xs={5} md={4}>
				<DisplayLineData
					label={<FormattedMessage id="dispatch.stock.unitProduct" defaultMessage="Conditionnement" description="Conditioning" />}
					data={movement.typeCondProduit}
					multiline
				/>
			</Grid>
			<Grid item xs={12}>
				<Typography variant={isLargeScreen ? 'h1' : 'h3'}>
					<FormattedMessage
						id="vaccination.exitFridge.formSection.batchNumber"
						defaultMessage="2. Sélectionner le lot"
						description="Form section title"
					/>
				</Typography>
			</Grid>
			<Grid item xs={12} md={6}>
				<DisplayLineData
					label={
						<FormattedMessage
							id="vaccination.exitFridge.batchNumber"
							defaultMessage="Numéro de lot / bon de livraison"
							description="Batch number"
						/>
					}
					data={`${batch.ligneBl.stock ? batch.ligneBl.stock.lot : ''} / ${batch.ligneBl.numeroBl}`}
					multiline
				/>
			</Grid>
			<Grid item xs={12} md={4}>
				<DisplayLineData
					label={<FormattedMessage id="global.functionnal.usageLimitDate" defaultMessage="Limite d'utilisation" description="Input for limit date" />}
					data={getUsageLimit(batch)}
					multiline
				/>
			</Grid>

			<Grid item xs={12}>
				<Typography variant={isLargeScreen ? 'h1' : 'h3'}>
					<FormattedMessage
						id="vaccination.exitFridge.formSection.exitSelection"
						defaultMessage="3. Sélectionner la sortie"
						description="Form section title"
					/>
				</Typography>
			</Grid>
			<Grid item xs={12} md={5}>
				<DisplayLineData
					label={<FormattedMessage id="global.functionnal.destination" defaultMessage="Destination" description="Exit type selection" />}
					data={intl.formatMessage({
						id: `vaccination.exitFridge.sortieType.VACCINATION_POINT`,
						defaultMessage: `Destination`,
						description: 'Exit type option'
					})}
					multiline
				/>
			</Grid>

			<Grid item xs={12} md={5}>
				<DisplayLineData
					label={
						<FormattedMessage
							id="vaccination.exitFridge.formSection.usingProduct.vaccinationPoint"
							defaultMessage="Point de vaccination"
							description="Destination field"
						/>
					}
					data={movement.vaccinationBl?.pointVacc.nom}
					multiline
				/>
			</Grid>
			<Grid item xs={12}>
				<Typography variant={isLargeScreen ? 'h1' : 'h3'}>
					<FormattedMessage
						id="vaccination.exitFridge.formSection.usingProduct"
						defaultMessage="4. Saisir la quantité"
						description="Form section title"
					/>
				</Typography>
			</Grid>
			{hasSeringue && <Grid item xs={12} container>
				<Grid item xs={4} md={2}>
					<DisplayLineData
						label={<FormattedMessage id="vaccination.exitFridge.seringue" defaultMessage="Seringue" description="Comment label input" />}
						data={movement.vaccinationBl?.seringueQte}
						multiline
					/>
				</Grid>
			</Grid>}
			<Grid item xs={12} container>
				<Grid item xs={4} md={2}>
					<DisplayLineData
						label={<FormattedMessage id={`vaccination.exitFridge.flacon${hasSeringue ? 'Consumed' : ''}`} defaultMessage="Flacon" description="Comment label input" />}
						data={movement.quantite}
						multiline
					/>
				</Grid>
			</Grid>
			{movement.vaccinationBl?.commentaire && <Grid item xs={12}>
				<DisplayLineData
					label={<FormattedMessage id="vaccination.replenishment.comment" defaultMessage="Commentaire" description="Comment label input" />}
					data={movement.vaccinationBl?.commentaire ?? ''}
					multiline
				/>
			</Grid>}
			<div
				className={classes.submitButtonRoot}
			>
				<Button type="button" onClick={downloadDocument} variant="contained" isLoading={isLoadingDocument}>
					<FormattedMessage id="button.print.initial" defaultMessage="Imprimer" description="Message on form submission button" />
				</Button>
			</div>
		</PageFormContainer>
	)
}

export default compose(injectSnackbarActions)(ExitFridgePrint)

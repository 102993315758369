import { TrackerModel } from '../../admin/tracker/services/trackerModel'
import { CarrierModel } from '../../admin/carrier/services/carrierModel'
import { ContainerModel } from '../../admin/container/services/containerModel'
import { ProductModel, VaccinModel } from '../../admin/product/services/productModel'
import { TemplateRowModel } from '../../admin/template/services/templateModels'
import { REPLENISHMENT_STATUS, SELECT_VALUES } from '../../../utils/constants'

export interface PhidemDataModel {
	user: {
		centerList: CentreInfoModel[],
		selectedCenter: CentreModel,
		isAdmin: boolean,
		isAdminDispatch: boolean,
		selectedLang: string,
		isDateFormatEn: boolean
	},
	vaccins: VaccinModel[],
	products: ProductModel[],
	templates: TemplateRowModel[],
	referentialData: PhidemReferentialDataModel,
	application: ApplicationModel,
	valueList: ValueListDicModel,
	reloadReferentiel: (key: string) => void
}

export interface PhidemReferentialDataModel {
	carriers: CarrierModel[],
	containers: ContainerModel[],
	trackers: TrackerModel[]
}

export interface ResponseValueListModel {
	id: string,
	code: string,
	label: string
}

export interface ValueListModel {
	[key: string]: string
}

export interface ValueListDicModel {
	[key: string]: ValueListModel
}

export interface CentreModel {
	id: string,
	nom: string,
	type: string,
	numFiness?: string,
	service?: string,
	siret?: string,
	adresse: string,
	codePostal: string,
	ville: string,
	telephone?: string,
	actif: boolean,
	gestionStock: boolean,
	desactivationSuivi: boolean,
	modeQte?: string,
	modeAdministration?: string,
	idReferent?: string,
	idTransporteur?: string
	centreParents: CentreModel[]
	centreEnfants: CentreModel[]

	// Distinction des enfants selon usage front
	centreEnfantsByType: {
		vaccinations: CentreModel[],
		refDispatchs: CentreModel[]
	}
}

export interface CentreInfoModel {
	id: string,
	nom: string,
	type: string,
	service?: string
}

export interface CentreInfoMailingModel {
	id: string,
	nom: string,
	type: string,
	service?: string,
	referentEmail?: string,
	centerEmail?: string
}

export interface HistoryModel {
	creationDate: string,
	modificationDate: string,
	creationUser: string,
	modificationUser: string
}

export interface AuthorityModel {
	authority: string
}

export interface ApplicationConfiguration {
	defaultLanguage: string
	replenishmentCoverage: boolean,
	replenishmentDose: boolean,
	transfertPreparation: boolean
}

export interface ApplicationModel {
	replenishment: {
		filters: any,
		setFilters: (filters: any) => void
	},
	replenishmentPoint: {
		filters: any,
		setFilters: (filters: any) => void
	},
	configuration: ApplicationConfiguration
}

export const initCentre = {
	id: '',
	nom: '',
	type: '',
	numFiness: undefined,
	service: undefined,
	siret: undefined,
	adresse: '',
	codePostal: '',
	ville: '',
	telephone: undefined,
	actif: false,
	gestionStock: false,
	desactivationSuivi: false,
	idReferent: undefined,
	centreParents: [],
	centreEnfants: [],
	centreEnfantsByType: {
		vaccinations: [],
		refDispatchs: []
	}
}

export const initDispatchData = {
	carriers: [],
	containers: [],
	trackers: []
}

export const initApplication = {
	replenishment: {
		filters: {
			idProduit: undefined,
			idCentre: undefined,
			statut: [REPLENISHMENT_STATUS.EN_ATTENTE, REPLENISHMENT_STATUS.CONFIRME],
			dateRole: SELECT_VALUES.NONE,
			isDateDemande: false,
			isDateReception: false,
			dateDebut: undefined,
			dateFin: undefined,
			idTransporteur: undefined,
			typeEtiquette: undefined,
			texteEtiquette: undefined
		},
		setFilters: () => {
		}
	},
	replenishmentPoint: {
		filters: {
			idProduit: undefined,
			idCentre: undefined,
			statut: [REPLENISHMENT_STATUS.EN_ATTENTE],
			dateRole: SELECT_VALUES.NONE,
			isDateDemande: false,
			isDateReception: false,
			dateDebut: undefined,
			dateFin: undefined
		},
		setFilters: () => {
		}
	},
	configuration: {
		defaultLanguage: 'en',
		replenishmentCoverage: false,
		replenishmentDose: false,
		transfertPreparation: false
	}
}

export const REFERENTIEL = {
	CENTER: 'CENTER',
	CARRIER: 'CARRIER',
	CONTAINER: 'CONTAINER',
	TRACKER: 'TRACKER',
	PRODUCT: 'PRODUCT',
	TEMPLATE: 'TEMPLATE'
}

import { CentreInfoModel } from '../../../common/phidemData/phidemDataModel'
import { TemplateRowModel } from '../../template/services/templateModels'
import { ADMIN_MODE } from '../../../../utils/constants'

export interface CenterUsersDataModel {
	id: string
	email: string
	firstname: string
	lastname: string
	login: string
}

export interface EtiquetteCentreModel {
	categorieType: string,
	categorieLabel?: string, // label defined means the type is equal to `AUTRE`
	valeur: string
}

export interface CenterDetailsDataModel {
	id: string,
	nom: string,
	type: string,
	numFiness?: string,
	siret?: string,
	adresse: string,
	codePostal: string,
	ville: string,
	actif: boolean,
	idReferent?: string,
	idTransporteur?: string,
	centreEnfants: CenterDetailsDataModel[],
	centreParents: CentreInfoModel[],
	users: CenterUsersDataModel[],
	ape?: string,
	service?: string,
	telephone?: string,
	libelleRoutage?: string,
	capacite?: number,
	complementDistrib?: string,
	gestionStock: boolean,
	desactivationSuivi: boolean,
	etiquettes: EtiquetteCentreModel[],
	email?: string,
	destinataireEmail: string,
	emailAutorise: string[],
	templates: TemplateRowModel[],
	utilisation: string
}

export interface RequestCreateOrUpdateCenterDetailsModel {
	nom: string,
	service?: string,
	type: string,
	capacite?: number,
	numFiness?: string,
	siret?: string,
	ape?: string,
	complementDistrib?: string,
	adresse: string,
	codePostal: string,
	ville: string,
	libelleRoutage?: string,
	telephone?: string,
	email?: string,
	gestionStock: boolean,
	desactivationSuivi: boolean,
	modeQte?: string,
	modeAdministration: string,
	users: string[],
	idReferent?: string,
	idTransporteur?: string,
	childrenIds: string[],
	etiquettes: EtiquetteCentreModel[]
	destinataireEmail: string,
	emailAutorise: string[],
	templates: string[]
	actif: boolean,
	id?: string,
	childrenCenters: CenterDetailsDataModel[],
	parentCenters: CentreInfoModel[],
	userCenters: CenterUsersDataModel[]
}

export const defaultRequestCenter = {
	nom: '',
	type: '',
	adresse: '',
	codePostal: '',
	ville: '',
	users: [],
	childrenIds: [],
	actif: false,
	gestionStock: false,
	desactivationSuivi: false,
	modeQte: undefined,
	childrenCenters: [],
	parentCenters: [],
	userCenters: [],
	etiquettes: [],
	destinataireEmail: '',
	emailAutorise: [],
	templates: [],
	modeAdministration: ADMIN_MODE.SEMAINE
}

export interface RequestCentersFiltersModel {
	statut: string,
	type?: string,
	nom?: string
}

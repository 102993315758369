import {
	isAuthorized as isAuthorizedApi,
	IsotopeContext,
	loadLangues,
	logOut as logOutAction,
	Page,
	ProfileMenu,
	ProfilePage,
	refresh as refreshApi,
	refreshToken as refreshTokenAction,
	storeLocalUser as storeLocalUserApi,
	ToolbarContext,
	UsersPage,
	ValueListPage
} from 'isotope-client'
import 'moment/locale/fr'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Link, Redirect, Route, Switch } from 'react-router-dom'
import AccueilPage from '../modules/accueil/AccueilPage'
import EnterStockVaccination from '../modules/vaccination/enterStock/EnterStock'
import ExitFridge from '../modules/vaccination/exitFridge/ExitFridge'
import ExitStock from '../modules/dispatch/exitStock/ExitStock'
import ExitStockReferent from '../modules/common/dispatchAndReferent/exitStock/ExitStock'
import ExitStockPrintReferent from '../modules/common/dispatchAndReferent/exitStock/ExitStockPrint'
import Scrapping from '../modules/common/dispatchAndReferent/scrapping/Scrapping'
import ScrappingVaccination from '../modules/vaccination/scrapping/Scrapping'
import PhidemDataProvider from '../modules/common/phidemData/PhidemDataProvider'
import ExitStockPrint from '../modules/dispatch/exitStock/ExitStockPrint'
import ScrappingPrint from '../modules/common/dispatchAndReferent/scrapping/ScrappingPrint'
import ScrappingPrintVaccination from '../modules/vaccination/scrapping/ScrappingPrint'
import CenterList from '../modules/admin/center/CenterList'
import OnlyDesktop from '../components/layout/OnlyDesktop'
import PageLayout from '../components/layout/PageLayout'
import TrackerList from '../modules/admin/tracker/TrackerList'
import ContainerList from '../modules/admin/container/ContainerList'
import CarrierList from '../modules/admin/carrier/CarrierList'
import ProductList from '../modules/admin/product/ProductList'
import Dashboard from '../modules/common/dashboard/Dashboard'
import DeclarationOfUse from '../modules/vaccination/declarationOfUse/DeclarationOfUse'
import Replenishment from '../modules/vaccination/replenishment/Replenishment'
import MovementHistory from '../modules/common/dispatchAndReferent/movementHistory/MovementHistory'
import MovementHistoryVaccination from '../modules/vaccination/movementHistory/MovementHistory'
import CenterDetailsCreate from '../modules/admin/center/CenterDetailsCreate'
import CenterDetailsUpdate from '../modules/admin/center/CenterDetailsUpdate'
import ProductCreate from '../modules/admin/product/ProductCreate'
import ProductUpdate from '../modules/admin/product/ProductUpdate'
import TrackerCreate from '../modules/admin/tracker/TrackerCreate'
import TrackerUpdate from '../modules/admin/tracker/TrackerUpdate'
import ContainerCreate from '../modules/admin/container/ContainerCreate'
import ContainerUpdate from '../modules/admin/container/ContainerUpdate'
import CarrierCreate from '../modules/admin/carrier/CarrierCreate'
import CarrierUpdate from '../modules/admin/carrier/CarrierUpdate'
import ReplenishmentList from '../modules/common/replenishment/ReplenishmentList'
import StockAdmin from '../modules/admin/stock/components/StockAdmin'
import StockVaccination from '../modules/vaccination/stock/components/StockVaccination'
import StockDispatchSelf from '../modules/dispatch/stock/StockDispatchSelf'
import StockDispatchAffiliatedVaccinationCenters from '../modules/dispatch/stock/StockDispatchAffiliatedVaccinationCenters'
import StockReferentSelf from '../modules/referent/stock/StockReferentSelf'
import BlocHeader from '../components/layout/BlocHeader'
import { CENTER_TYPE, LOCAL_STORAGE_ITEM, USER_ROLE } from '../utils/constants'
import ProductReturn from '../modules/dispatch/productReturn/ProductReturn'
import ProcurementList from '../modules/common/procurement/ProcurementList'
import ProcurementAdd from '../modules/common/procurement/ProcurementAdd'
import ProcurementUpdate from '../modules/common/procurement/ProcurementUpdate'
import TemplateList from '../modules/admin/template/TemplateList'
import TemplateCreate from '../modules/admin/template/TemplateCreate'
import TemplateUpdate from '../modules/admin/template/TemplateUpdate'
import ProductReturnPrint from '../modules/dispatch/productReturn/ProductReturnPrint'
import LegalNotice from '../modules/common/LegalNotice'
import HelpOutlineIcon from '@material-ui/icons/HelpOutline'
import MailForm from '../modules/admin/mail/MailForm'
import UsageControl from '../modules/common/dispatchAndReferent/declarationOfUse/UsageControl'
import ReplenishmentDemand from '../modules/pointVaccination/replenishment/ReplenishmentDemand'
import VaccinPointReplenishmentList from '../modules/common/replenishment/VaccinPointReplenishmentList'
import ExitFridgePrint from '../modules/vaccination/exitFridge/ExitFridgePrint'
import LanguageSelector from '../config/messages/LanguageSelector'
import EnterStock from '../modules/common/dispatchAndReferent/enterStock/EnterStock'

/**
 * Interval de rafraîchissement du token, en secondes.
 */
const REFRESH_INTERVAL = 1700

class App extends Component {

	constructor(props) {
		super(props)
		const { user, location, logout } = this.props
		if (user.authenticated) {
			// Si le user est bien authentifié, je vérifie les droits
			isAuthorizedApi(location.pathname)
				.then(retour => {
					if (!retour) logout()
				})
		}

		this.refresh()
		this.refreshInterval = setInterval(() => {
			this.refresh()
		}, REFRESH_INTERVAL * 1000)
	}

	refresh() {
		const { user, refreshAction, loadLanguesAction } = this.props
		if (user && user.token) {
			const token = localStorage.getItem(LOCAL_STORAGE_ITEM.TOKEN)
			refreshApi(token).then((json) => {
				if (json) {
					refreshAction(json.token)
					storeLocalUserApi(json)
				}
			})
		}
		loadLanguesAction()
	}

	componentWillUnmount() {
		if (this.refreshInterval) {
			clearInterval(this.refreshInterval)
		}
	}

	render() {
		const { location, user, session } = this.props

		if (user.authenticated) {
			return (
				<IsotopeContext.Provider value={{ appName: '', logo: BlocHeader }}>
					<ToolbarContext.Provider value={{
						children: [
							<Link key="notice" to="/notice"><HelpOutlineIcon style={{ color: '#FFFFFF', marginRight: 10 }} /></Link>,
							<LanguageSelector key="language"/>,
							<ProfileMenu key="profile" />
						]
					}}>
						<PhidemDataProvider>
							<Page location={location} menu={session.centerId || 'ADMIN'}>
								<Switch>
									<Route exact path="/" component={AccueilPage} />
									<Route path="/me" component={ProfilePage} />
									<Route path="/notice" component={LegalNotice} />

									{/* REFERENT */}
									<Route path="/referent/stock-entries" render={() => <EnterStock centerType={CENTER_TYPE.REFERENT} />} />
									<Route exact path="/referent/exits" component={ExitStockReferent} />
									<Route exact path="/referent/exits/:id" component={ExitStockPrintReferent} />
									<Route exact path="/referent/scrappings" render={() => <Scrapping centerType={CENTER_TYPE.REFERENT} />} />
									<Route path="/referent/scrappings/:id" render={() => <ScrappingPrint centerType={CENTER_TYPE.REFERENT} />} />
									<Route exact path="/referent/dashboard" component={Dashboard} />
									<Route exact path="/referent/stocks" component={StockReferentSelf} />
									<Route exact path="/referent/affiliated-stocks" render={() => <StockAdmin fromReferentAffiliatedView />} />

									{/* DISPATCH */}
									<Route path="/dispatch/stock-entries" render={() => <EnterStock centerType={CENTER_TYPE.DISPATCH} />} />
									<Route exact path="/dispatch/exits" component={ExitStock} />
									<Route path="/dispatch/exits/:id" component={ExitStockPrint} />
									<Route exact path="/dispatch/scrappings" render={() => <Scrapping centerType={CENTER_TYPE.DISPATCH} />} />
									<Route path="/dispatch/scrappings/:id" render={() => <ScrappingPrint centerType={CENTER_TYPE.DISPATCH} />} />
									<Route exact path="/dispatch/dashboard" component={Dashboard} />
									<Route exact path="/dispatch/return" component={ProductReturn} />
									<Route exact path="/dispatch/return/:id" component={ProductReturnPrint} />
									<Route exact path="/dispatch/stocks" component={StockDispatchSelf} />
									<Route exact path="/dispatch/referent/stocks" component={StockDispatchAffiliatedVaccinationCenters} />

									{/* VACCINATION */}
									<Route path="/vaccination/stock-entries" component={EnterStockVaccination} />
									<Route exact path="/vaccination/exits" component={ExitFridge} />
									<Route path="/vaccination/exits/:id" component={ExitFridgePrint} />
									<Route path="/vaccination/declarations-of-use" component={DeclarationOfUse} />
									<Route exact path="/vaccination/scrappings" component={ScrappingVaccination} />
									<Route path="/vaccination/scrappings/:id" component={ScrappingPrintVaccination} />
									<Route path="/vaccination/replenishment-form" component={Replenishment} />
									<Route path="/vaccination-point/replenishment-form" component={ReplenishmentDemand} />
									<Route exact path="/vaccination/dashboard" component={Dashboard} />
									<Route exact path="/vaccination/stocks" component={StockVaccination} />

									{/* ADMIN */}
									<Route exact path="/stocks" component={StockAdmin} />

									{/* DESKTOP ONLY */}
									<OnlyDesktop>
										<PageLayout>
											<Switch>
												<Route exact path="/dispatch/replenishments" render={() => <ReplenishmentList role={USER_ROLE.ROLE_DISPATCH_CENTER} />} />
												<Route exact path="/dispatch/movements" render={() => <MovementHistory centerType={CENTER_TYPE.DISPATCH} />} />
												<Route exact path="/procurements" component={ProcurementList} />
												<Route exact path="/procurements/add" component={ProcurementAdd} />
												<Route exact path="/procurements/:id" component={ProcurementUpdate} />
												<Route exact path="/vaccination/replenishments" render={() => <ReplenishmentList role={USER_ROLE.ROLE_VACCINATION_CENTER} />} />
												<Route exact path="/vaccination/affiliated-replenishments" render={() => <VaccinPointReplenishmentList role={USER_ROLE.ROLE_VACCINATION_CENTER} />} />
												<Route exact path="/vaccination-point/replenishments" render={() => <VaccinPointReplenishmentList role={USER_ROLE.ROLE_VACCINATION_POINT} />} />
												<Route exact path="/vaccination/movements" component={MovementHistoryVaccination} />
												<Route exact path="/usage-control" component={UsageControl} />

												{/* REFERENT */}
												<Route exact path="/referent/movements" render={() => <MovementHistory centerType={CENTER_TYPE.REFERENT} />} />

												{/* ADMIN */}
												<Route path="/users" component={UsersPage} />
												<Route exact path="/centers" component={CenterList} />
												<Route exact path="/centers/add" component={CenterDetailsCreate} />
												<Route exact path="/centers/:id" component={CenterDetailsUpdate} />
												<Route exact path="/products" component={ProductList} />
												<Route exact path="/products/add" component={ProductCreate} />
												<Route exact path="/products/:id" component={ProductUpdate} />
												<Route exact path="/trackers" component={TrackerList} />
												<Route exact path="/trackers/add" component={TrackerCreate} />
												<Route exact path="/trackers/:id" component={TrackerUpdate} />
												<Route exact path="/containers" component={ContainerList} />
												<Route exact path="/containers/add" component={ContainerCreate} />
												<Route exact path="/containers/:id" component={ContainerUpdate} />
												<Route exact path="/carriers" component={CarrierList} />
												<Route exact path="/carriers/add" component={CarrierCreate} />
												<Route exact path="/carriers/:id" component={CarrierUpdate} />
												<Route exact path="/mail" component={MailForm} />
												<Route exact path="/replenishments" render={() => <ReplenishmentList role={USER_ROLE.ROLE_ADMIN} />} />
												<Route exact path="/point-replenishments" render={() => <VaccinPointReplenishmentList role={USER_ROLE.ROLE_ADMIN} />} />
												<Route exact path="/templates" component={TemplateList} />
												<Route exact path="/templates/add" component={TemplateCreate} />
												<Route exact path="/templates/:id" component={TemplateUpdate} />

												<Route exact path="/value-list" component={ValueListPage} />
												<Route exact path="/value-list/:id" component={ValueListPage} />
											</Switch>
										</PageLayout>
									</OnlyDesktop>
								</Switch>
							</Page>
						</PhidemDataProvider>
					</ToolbarContext.Provider>
				</IsotopeContext.Provider>
			)
		}
		return (
			<Redirect to="/login" />
		)
	}
}

App.propTypes = {
	location: PropTypes.object.isRequired,
	logout: PropTypes.func.isRequired,
	refreshAction: PropTypes.func.isRequired,
	user: PropTypes.object,
	session: PropTypes.object,
	loadLanguesAction: PropTypes.func.isRequired
}

const mapStateToProps = (state) => ({
	user: state.user,
	session: state.session
})

const actions = {
	refreshAction: refreshTokenAction,
	logout: logOutAction,
	loadLanguesAction: loadLangues
}

export default connect(mapStateToProps, actions)(App)
